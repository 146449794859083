import Vue from "vue";
import Vuex from "vuex";

import { module as Proposal } from "./modules/proposal";
import { module as User } from "./modules/user";
import { module as Dashboard } from "./modules/dashboard";
import { module as StatusGroup } from "./modules/statusGroup";
import { module as BankLoanConfig } from "./modules/bankLoanConfig";
import { module as Template } from "./modules/template";
import dinheirow from "@/whitelabel/labels/dinheirow";
import { GlobalError } from "@/store/modules/globalError";
import { IOnboardAPI } from "@/services/api/types/OnboardAPI";
import { IBrand } from "@/types/whitelabel";
import { statusGroupState } from "@/store/modules/statusGroup/state";
import { BankLoanConfigState } from "@/store/modules/bankLoanConfig/state";
import { TemplateState } from "@/store/modules/template/state";

Vue.use(Vuex);

export type RootState = {
  statusGroup: statusGroupState;
  BankLoanConfig: BankLoanConfigState;
  Whitelabel: { whitelabel: IBrand };
  OnboardApi: { onboardApi: IOnboardAPI };
  Template: TemplateState;
};

export default new Vuex.Store<RootState>({
  modules: {
    Template,
    BankLoanConfig,
    StatusGroup,
    Dashboard,
    Proposal,
    User,
    GlobalError,
    Loading: {
      state: {
        isLoading: false,
      },
      getters: {
        isLoading(state) {
          return state.isLoading;
        },
      },
      mutations: {
        isLoading(state, payload) {
          state.isLoading = payload;
        },
      },
      actions: {
        isLoading(context, payload) {
          context.commit("isLoading", payload);
        },
      },
    },
    Whitelabel: {
      state: {
        whitelabel: dinheirow,
      },
      getters: {
        whiteLabel(state) {
          return state.whitelabel;
        },
        isConsiga(state) {
          return state.whitelabel.brand.includes("consiga");
        },
      },
      mutations: {
        setWhiteLabel(state, payload) {
          state.whitelabel = payload;
        },
      },
      actions: {
        whiteLabel(context, payload) {
          context.commit("setWhiteLabel", payload);
          context.commit("setShowHelpButtons", payload.additionalConfigs?.showHelpButton ?? true);
        },
      },
    },
    ShowHelpButtons: {
      state: {
        showHelpButtons: true,
      },
      getters: {
        showHelpButtons(state) {
          return state.showHelpButtons;
        },
      },
      mutations: {
        setShowHelpButtons(state, payload) {
          state.showHelpButtons = payload;
        },
      },
    },
    OnboardApi: {
      state: {
        onboardApi: {} as IOnboardAPI,
      },
      getters: {
        onboardApi(state) {
          return state.onboardApi;
        },
      },
      mutations: {
        setOnboardApi(state, payload) {
          state.onboardApi = payload;
        },
      },
      actions: {
        onboardApi(context, payload) {
          context.commit("setOnboardApi", payload);
        },
      },
    },
  },
});
