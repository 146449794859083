import { IBankLoanConfigStatusModel } from "@/types/models/BankLoanConfigStatus";
import { IStatusModel } from "@/types/models/Status";
import { IStatusGroupModel } from "@/types/models/StatusGroup";
import { ICheckpointModel } from "@/types/models/Checkpoint";
import { TemplateElement } from "@/types/models/TemplateElement";
import { StatusPlugins } from "@/types/models/StatusPlugin";

export default class BankLoanConfigStatusModel {
  public backoffice_description: string | null;
  public borrower_description: string | null;
  public lender_description: string | null;
  public order: number | null;
  public status: IStatusModel | null;
  public status_group: IStatusGroupModel | null;
  public checkpoint: ICheckpointModel;
  public status_id: number | null;
  public restricted_screen: boolean;
  public template?: TemplateElement[];
  public plugins?: StatusPlugins;

  constructor({
    backoffice_description,
    borrower_description,
    lender_description,
    order,
    status,
    status_id,
    status_group,
    checkpoint,
    restricted_screen,
    template,
    plugins,
  }: IBankLoanConfigStatusModel) {
    this.backoffice_description = backoffice_description;
    this.borrower_description = borrower_description;
    this.lender_description = lender_description;
    this.order = order;
    this.status = status;
    this.status_id = status_id;
    this.status_group = status_group;
    this.checkpoint = checkpoint;
    this.restricted_screen = restricted_screen;
    this.template = template;
    this.plugins = plugins;
  }
}
